import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

export default (query, options = {}) => {
  const params = useParams()
  const locale = params.language

  const protocol = process.env.IS_SERVER
    ? 'http'
    : process.env.REACT_APP_PROTOCOL
  const hostname = process.env.IS_SERVER
    ? 'proxy'
    : process.env.REACT_APP_HOST_NAME

  const uri = `${protocol}://${hostname}${locale ? `/${locale}` : ''}/graphql`

  const result = useQuery(query, { ...options, context: { uri } })

  return result
}
