import { gql } from '@apollo/client'
import useLocalizedQuery from 'containers/useLocalizedQuery'

export default (size = 8, page = 1) => {
  const query = gql`
    query Posts($size: Int, $offset: Int) {
      posts(
        where: {
          orderby: { field: DATE, order: DESC }
          offsetPagination: { size: $size, offset: $offset }
        }
      ) {
        nodes {
          id
          postId
          title
          date
          excerpt
          link
          featuredImage {
            sourceUrl
          }
        }
        pageInfo {
          offsetPagination {
            hasMore
            hasPrevious
            total
          }
        }
      }
    }
  `

  const variables = {
    size: size,
    offset: (page - 1) * size
  }

  const { data, loading, error } = useLocalizedQuery(query, {
    variables
  })

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }

  return {
    data: loading ? null : data?.posts?.nodes,
    pagination: data?.posts?.pageInfo?.offsetPagination,
    loading
  }
}
