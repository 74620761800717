/* eslint-disable no-console */
import { useCallback } from 'react'
import { gql } from '@apollo/client'
import useLocalizedQuery from 'containers/useLocalizedQuery'

export default () => {
  const { data, loading, error } = useLocalizedQuery(gql`
    query Strings {
      optionsStrings {
        readMore
      }
    }
  `)

  if (error) {
    console.error('Error fetching strings', error)
  }

  const t = useCallback(
    key => {
      let result = ''

      if (!loading) {
        const parts = key.split('.')
        try {
          if (parts.length > 1) {
            result = data.optionsStrings[parts[0]][parts[1]]
          } else {
            result = data.optionsStrings[parts[0]]
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        return ''
      }

      if (!result) {
        console.log('Returning empty string for key ', key)
      }
      return result
    },
    [data, loading]
  )

  return { data: data?.optionsStrings, loading, error, t }
}
