import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import responsiveSpacing from 'ui/styles/responsiveSpacing'

export default class Module extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    nextType: PropTypes.string,
    children: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
  }

  static defaultProps = {
    nextType: null
  }

  getSpacing() {
    const { type, nextType } = this.props

    // Define full element types
    const full = ['focusLinks', 'featureSlider']

    // Spacing between non-full and full elements
    if (!full.includes(type) && full.includes(nextType)) {
      return 3
    }

    // No spacing between fulll elements
    if (full.includes(type) && (full.includes(nextType) || !nextType)) {
      return 0
    }

    return 3
  }

  render() {
    const { children, index } = this.props

    return (
      <Wrapper spacing={this.getSpacing()} index={index}>
        {children}
      </Wrapper>
    )
  }
}

const entry = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const Wrapper = styled.div`
  position: relative;
  animation: ${entry} 700ms ${props => props.index * 200 + 200}ms backwards;
  ${props => responsiveSpacing(props.spacing)};
`
