import React from 'react'

export default props => (
  <svg
    width="37"
    height="34"
    viewBox="0 0 37 34"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#FFF" fillRule="evenodd">
      <path d="M5.925 4.082L7.39 8.645 1.457 8.33 0 11.419l8.587.503L11.31 20l2.956-2.163-2.31-6.832L18 7.266 15.077 5.24l-4.106 2.517L8.907 2z" />
      <path d="M18.386 0l2.175 7.395L13 12.172 16.182 14l6.394-4.046 5.795 3.95 1.191-3.088-3.913-2.69L31 4.619l-3.106-1.875L23.6 5.38 22.065.28z" />
      <path d="M21 14.347l6.101 4.295-2.383 6.29L28.28 25l1.633-4.255 5.233 3.557 1.027-3.296-4.015-2.911L37 15.153v-.004l-.802-3.288-7.036 4.222L21.953 11z" />
      <path d="M21.364 19.09l-2.531 6.433L12 25.184l.916 3.089 4.63.247L15.497 34l3.52-.013 1.748-4.256 4.226 3.373L28 31.514 21.913 26.6 24.914 19z" />
      <path d="M5.14 14.92l1.324 4.368L0 19.297l1.219 3.251 5.074.14-2.099 5.08L6.896 30l3.083-7.338 9.021.136-1.311-3.252-7.632-.107L8.072 13z" />
    </g>
  </svg>
)
