import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'
import { responsiveText } from 'ui/styles/index'

import theme from 'ui/styles/theme'

const { colors } = theme

export default ({ heading, clients }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col>
            <Heading>{heading}</Heading>
          </Col>
        </Row>
        <Row smAlignItems="stretch">
          {clients.map((client, index) => (
            <Col sm={clients.length <= 4 ? 12 / clients.length : 3}>
              <a
                href={client.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Logo>
                  <img src={client.logo.sourceUrl} alt="" />
                </Logo>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  clients {
    logo {
      sourceUrl
    }
    website
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  background: ${colors.tones.lightest};
  padding: 20px 0 50px;
  margin-bottom: 35px;

  a {
    display: block;
    width: 100%;

    ${media.sm`
      height: 100%;
    `}
  }
`

const Heading = styled.h2`
  ${responsiveText('xxs')};
  text-transform: uppercase;
  margin: 0 0 30px;
`

const Logo = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
`
