import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import TagManager from 'react-gtm-module'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import GridView from 'ui/components/generic/GridView'
import useSiteMeta from 'containers/useSiteMeta'
import useRedirects from 'containers/useRedirects'
import theme from 'ui/styles/theme'
import GlobalStyles from 'ui/styles/global'
import Navigation from 'ui/components/Navigation'
import Footer from 'ui/components/Footer'
import Page from 'ui/components/Page'
import Loading from 'ui/components/generic/Loading'
import getRelativeUrl from 'utils/getRelativeUrl'

export default () => {
  const { data: siteMeta, loading: siteMetaLoading } = useSiteMeta()
  const { redirects, redirectsLoading } = useRedirects()
  const { location } = useLocation()

  useEffect(() => {
    // Initialize tag manager
    process.env.NODE_ENV === 'production' &&
      siteMeta &&
      siteMeta.gtmId &&
      TagManager.initialize({ gtmId: siteMeta.gtmId })
  }, [siteMeta])

  if (siteMetaLoading || redirectsLoading) {
    return <Loading />
  }

  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={theme.grid}>
        <Switch location={location}>
          {redirects &&
            redirects.map(({ from, to }) => (
              <Redirect key={from} exact from={from} to={getRelativeUrl(to)} />
            ))}
        </Switch>
        <BaseCSS />
        <GlobalStyles />
        <Navigation />
        <Page />
        <Footer />
        {process.env.NODE_ENV === 'development' && <GridView />}
      </GridThemeProvider>
    </ThemeProvider>
  )
}
