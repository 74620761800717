import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'

import theme from 'ui/styles/theme'
import responsiveText from 'ui/styles/responsiveText'

const { colors, fontWeights } = theme

export default ({ heading, teamMembers }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        {heading && (
          <Row alignItems="center">
            <Col sm={10} smOffset={1}>
              <h2>{heading}</h2>
            </Col>
          </Row>
        )}

        <Row alignItems="stretch">
          {teamMembers.map((member, index) => (
            <Col
              xs={6}
              sm={2}
              smOffset={index === 0 || index % 5 === 0 ? 1 : 0}
            >
              <Content>
                <div>
                  <Image image={member.image.sourceUrl} />
                  <Name>{member.title}</Name>
                  <Title>{member.jobtitle}</Title>
                </div>
                <ContactInfo>
                  <div>
                    {member.phone && (
                      <a href={`tel:${member.phone}`}>{member.phone}</a>
                    )}
                  </div>
                  <div>
                    <a href={`mailto:${member.email}`}>{member.email}</a>
                  </div>
                </ContactInfo>
              </Content>
            </Col>
          ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  teamMembers {
    title
    jobtitle
    image {
      sourceUrl
    }
    phone
    email
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  margin-bottom: 35px;

  ${media.sm`
    margin-bottom: 150px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.tones.light};
  padding-bottom: 20px;
  margin-bottom: 20px;
  height: 100%;
`

const Image = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 135%;
  background: ${props =>
    props.image ? `url(${props.image})` : colors.tones.lightest};
  background-size: cover;
  background-position: center;
  margin-bottom: 0.5em;
  margin-top: 40px;

  ${media.sm`
    margin-top: 0;
  `}
`

const Name = styled.h3`
  ${responsiveText('sm')};
  font-weight: ${fontWeights.normal};
  color: ${colors.primaryDark};
  margin-bottom: 0.15em;
`

const Title = styled.h4`
  font-weight: ${fontWeights.normal};
  color: ${colors.tones.light};
`

const ContactInfo = styled.div`
  margin-top: 1em;

  a {
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    color: ${colors.primaryDark};
    border: 1px solid ${colors.tones.light};
    padding: 5px 0.5em 7px;
    border-radius: 1em;
    margin-top: 0.75em;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${media.sm`
      white-space: normal;
    `}
  }
`
