import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { responsiveText } from 'ui/styles'
import useScrollEntry from 'utils/useScrollEntry'

import theme from 'ui/styles/theme'

const { colors } = theme

export default ({ alignment, heading, text }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col sm={4} smOffset={1} smOrder={alignment === 'left' ? 1 : 2}>
            <h2>{heading}</h2>
          </Col>
          <Col sm={5} smOffset={1} smOrder={alignment === 'left' ? 2 : 1}>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  alignment
  heading
  text
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  margin-bottom: 35px;

  ${media.sm`
    margin-bottom: 150px;
  `}
`

const Text = styled.div`
  ${responsiveText('sm')};
  color: ${colors.textLight};
`
