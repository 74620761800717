import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  transform: rotate(${props => (props.open ? 0 : 45)}deg);
`

export default props => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    {...props}
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      d="M1 .001l18 18M1 18L19 0"
    ></path>
  </Svg>
)
