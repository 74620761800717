import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'
import theme from 'ui/styles/theme'
import { responsiveText } from 'ui/styles/index'

const { colors } = theme

export default ({ heading, image, mobileImage, types }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)
  const [isMobile, setIsMobile] = useState(false)

  const checkSize = useCallback(() => {
    const width = window.innerWidth

    const sizeIsMobile = width <= theme.grid.breakpoints.md

    if (sizeIsMobile && !isMobile) {
      setIsMobile(true)
    } else if (!sizeIsMobile && isMobile) {
      setIsMobile(false)
    }
  }, [isMobile])

  useEffect(() => {
    checkSize()

    window.addEventListener('resize', checkSize)

    return () => {
      window.removeEventListener('resize', checkSize)
    }
  }, [checkSize])

  return (
    <Wrapper ref={ref} visible={visible}>
      <ImageContainer>
        <HeadingContainer>
          {[...Array(6)].map((item, index) => (
            <Heading key={`heading-${index}`}>{heading}</Heading>
          ))}
          <FillHeading>{heading}</FillHeading>
        </HeadingContainer>
        <Image image={isMobile ? mobileImage.sourceUrl : image.sourceUrl} />
      </ImageContainer>

      <TextContainer>
        <Container fluid>
          <Row>
            <Col>
              <SubHeading>{heading}</SubHeading>
            </Col>
          </Row>
          <Row>
            {types.map((type, index) => (
              <Col sm={12 / types.length} key={`type-${index}`}>
                <Icon src={type.icon.sourceUrl} alt="" />
                <Title>{type.title}</Title>
                <p>{type.text}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </TextContainer>
    </Wrapper>
  )
}

export const query = `
  heading
  image {
    sourceUrl
  }
  mobileImage {
    sourceUrl
  }
  types {
    icon {
      sourceUrl
    }
    title
    text
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  text-align: center;
  margin-bottom: 35px;

  ${media.sm`
    margin-bottom: 150px;
  `}
`

const ImageContainer = styled.div`
  position: relative;
`

const Image = styled.div`
  background: url(${props => props.image}) center / cover;
  width: 100%;
  height: 0;
  padding-bottom: 75%;

  ${media.sm`
    padding-bottom: 56.25%;
  `}
`

const HeadingContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
`

const Heading = styled.h3`
  font-size: 9vw;
  line-height: 8vw;
  color: white;
  text-transform: uppercase;
  margin: 0;
  transform: translateY(-100%);
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;

  ${media.sm`
    -webkit-text-stroke: 2px #fff;
    transform: translateY(10%);
  `}
`

const FillHeading = styled(Heading)`
  position: sticky;
  bottom: 10px;
  -webkit-text-stroke: none;
  -webkit-text-fill-color: white;
`

const TextContainer = styled.div`
  background: ${colors.tones.lightest};
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  margin-top: -20px;
  position: relative;
  z-index: 1;
  padding-bottom: 100px;

  p {
    color: ${colors.tones.medium};
    margin-bottom: 45px;

    ${media.sm`
      margin-bottom: 0;
    `}
  }
`

const SubHeading = styled.h4`
  ${responsiveText('xxs')};
  text-transform: uppercase;
  text-align: left;
  margin: 35px 0 60px;
`

const Icon = styled.img`
  margin: 0 auto 45px;
`

const Title = styled.h4`
  color: ${colors.primaryDark};
  margin-bottom: 0.25em;
`
