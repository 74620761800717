import React, { PureComponent } from 'react'
import styled, { keyframes } from 'styled-components'

const height = 5
const width = 30
const lineWidth = 1

const line1AnimationOpen = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(${height / 2}px) rotate(0deg) scale(0.6, 0.6);;
  }
  100% {
    transform: translateY(${height / 2}px) rotate(45deg) scale(0.6, 0.6);;
  }
`

const line1AnimationClose = keyframes`
  0% {
    transform: translateY(${height / 2}px) rotate(45deg) scale(0.6, 0.6);;
  }
  50% {
    transform: translateY(${height / 2}px) rotate(0deg) scale(0.6, 0.6);;
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`

const Line2AnimationClose = keyframes`
  0% {
    transform: translateY(-${height / 2 -
      lineWidth / 2}px) rotate(-45deg) scale(0.6, 0.6);;
  }
  50% {
    transform: translateY(-${height / 2 -
      lineWidth / 2}px) rotate(0deg) scale(0.6, 0.6);;
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`

const Line2AnimationOpen = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-${height / 2 -
      lineWidth / 2}px) rotate(0deg) scale(0.6, 0.6);
  }
  100% {
    transform: translateY(-${height / 2 -
      lineWidth +
      0.5}px) rotate(-45deg) scale(0.6, 0.6);
  }
`

const Line = styled.div`
  height: ${lineWidth}px;
`

const Line1 = styled(Line)`
  animation: ${props => (props.open ? line1AnimationOpen : line1AnimationClose)}
    1000ms both;
`

const Line2 = styled(Line)`
  animation: ${props => (props.open ? Line2AnimationOpen : Line2AnimationClose)}
    1000ms both;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  width: ${width + 20}px;
  height: ${height + 20}px;
  transition: all 200ms;
  cursor: pointer;
  backface-visibility: hidden;
  background-color: #fff;
  border-radius: 20px;

  ${Line} {
    background-color: ${props => props.color};
    transition: all 500ms, background-color 1000ms;
  }
`

export default class MenuIcon extends PureComponent {
  handleClick = e => {
    e.preventDefault()
    this.props.toggle()
  }

  render() {
    const { open, color, ...rest } = this.props
    return (
      <Wrapper
        onClick={this.handleClick}
        color={color || '#000'}
        open={open}
        {...rest}
      >
        <Line1 open={open} />
        {/* <Line2 open={open} /> */}
        <Line2 open={open} />
      </Wrapper>
    )
  }
}
