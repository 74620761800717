import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import useScrollEntry from 'utils/useScrollEntry'
import getRelativeUrl from 'utils/getRelativeUrl'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from 'ui/components/generic'

import theme from 'ui/styles/theme'
import easing from 'ui/styles/easing'

const { colors } = theme

const Accordion = ({ index, drawer, expanded, setExpanded }) => {
  const isOpen = index === expanded

  return (
    <Content>
      <Header
        initial={false}
        open={isOpen}
        onClick={() => setExpanded(isOpen ? false : index)}
      >
        <h4>{drawer.title}</h4>
        <Indicator open={isOpen}>
          <Dot />
          <Dot />
          <Dot />
        </Indicator>
      </Header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <Drawer
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <DrawerContent>
              <LeftCol>
                <h3>{drawer.heading}</h3>
              </LeftCol>
              <RightCol>
                <div dangerouslySetInnerHTML={{ __html: drawer.text }} />
                {drawer.buttonText && drawer.buttonLink && (
                  <Button to={getRelativeUrl(drawer.buttonLink)} negative>
                    {drawer.buttonText}
                  </Button>
                )}
              </RightCol>
            </DrawerContent>
          </Drawer>
        )}
      </AnimatePresence>
    </Content>
  )
}

export default ({ heading, accordion }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)
  const [expanded, setExpanded] = useState(0)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col sm={10} smOffset={1}>
            <h2>{heading}</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={10} smOffset={1}>
            {accordion.map((drawer, index) => (
              <Accordion
                index={index}
                drawer={drawer}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  accordion {
    title
    heading
    text
    buttonText
    buttonLink
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  margin-bottom: 65px;

  ${media.sm`
    margin-bottom: 150px;
  `}
`

const Content = styled.div`
  margin-bottom: 8px;
`

const Header = styled(motion.div)`
  padding: 15px 40px;
  background: ${colors.tones.lightest};
  border-radius: ${props => (props.open ? '20px 20px 0 0' : '20px')};
  position: relative;
  z-index: 1;
  transition: border-radius 0.8s ${easing.pop};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  h4 {
    margin: 0;
    font-weight: normal;
  }
`

const Drawer = styled(motion.div)`
  background: ${colors.primary};
  overflow: hidden;
  border-radius: 0 0 20px 20px;
`

const DrawerContent = styled.div`
  padding: 50px 40px 25px;
  color: white;
  display: flex;
  flex-wrap: wrap;
`

const LeftCol = styled.div`
  ${media.sm`
    width: 40%;
  `}
`

const RightCol = styled.div`
  ${media.sm`
    width: 60%;
  `}
`

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background: ${colors.primary};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-2px);
  border-radius: 100%;
  transition: transform 0.8s ${easing.pop};
`

const Indicator = styled.div`
  position: relative;
  width: 26px;
  height: 4px;

  ${Dot} {
    &:first-child {
      transform: translateX(${props => (props.open ? '-2px' : '-12px')});
    }

    &:last-child {
      transform: translateX(${props => (props.open ? '-2px' : '8px')});
    }
  }
`
