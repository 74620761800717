import React from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import scrollOnMount from 'utils/scrollOnMount'
import usePost from 'containers/usePost'
import useSiteMeta from 'containers/useSiteMeta'
import Modules from 'ui/components/Modules'
import Seo from 'ui/components/Seo'

function NotFound(props) {
  const { data: siteMeta } = useSiteMeta()
  const { data: post, loading, error } = usePost(
    siteMeta && siteMeta.notFoundSlug,
    'page'
  )

  if (error) {
    return (
      <h1
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          textAlign: 'center',
          marginBottom: '0px',
        }}
      >
        Neither the content you are looking for or the 404-page could be found.{' '}
        <br /> What a mess..
      </h1>
    )
  }

  return (
    <Wrapper>
      {!loading && <Seo seo={post.seo} />}

      {!loading && post.modules && <Modules post={post} />}
    </Wrapper>
  )
}

export default compose(withRouter, scrollOnMount)(NotFound)

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.navHeight}px;
  background-color: ${(props) => props.theme.colors.tones.dark};
  min-height: calc(100vh - ${(props) => props.theme.navHeight}px);
`
