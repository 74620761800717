import { gql } from '@apollo/client'

import useLocalizedQuery from 'containers/useLocalizedQuery'
import * as queries from 'ui/components/modules/queries'

export default uri => {
  const query = gql`
    query Content($uri: ID!) {
      contentNode(id: $uri, idType: URI) {
        __typename
        ... on Post {
          ${commonFields}
        }

        ... on Page {
          ${commonFields}
          template {
            ... on DefaultTemplate {
              templateName
            }
            ... on ForsideTemplate {
              templateName
            }
          }
        }
      }
    }
  `

  const { loading, error, data } = useLocalizedQuery(query, {
    variables: { uri }
  })

  return {
    data: data?.contentNode && parseData(data.contentNode),
    loading,
    error
  }
}

// join queries from all modules
const modulesQuery = Object.keys(queries)
  .map(
    key => `
  ...on Modules__${key} {
    acfFcLayout
    ${queries[key]}
  }
`
  )
  .join('')

// Fields queried for all post types
const commonFields = `
  id
  title
  seo
  slug
  header {
    type
    mediaType
    textLine1
    textLine2
    subHeader
    icon
    disableGradient
    image {
      sourceUrl
      altText
    }
    video {
      mediaItemUrl
      mediaDetails {
        height
        width
      }
    }
  }
  modules {
    ${modulesQuery}
  }
`

const parseData = data => ({
  ...data,
  seo: JSON.parse(data.seo)
})
