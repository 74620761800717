import React, { PureComponent } from 'react'
import styled from 'styled-components'

import Module from 'ui/components/Module'

import Accordion from 'ui/components/modules/Accordion'
import Clients from 'ui/components/modules/Clients'
import Contact from 'ui/components/modules/Contact'
import Expertise from 'ui/components/modules/Expertise'
import FeatureSlider from 'ui/components/modules/FeatureSlider'
import FocusLinks from 'ui/components/modules/FocusLinks'
import Gallery from 'ui/components/modules/Gallery'
import HeadingText from './modules/HeadingText'
import Image from './modules/Image'
import ImageText from 'ui/components/modules/ImageText'
import LoadTypes from 'ui/components/modules/LoadTypes'
import News from 'ui/components/modules/News'
import RelatedNews from 'ui/components/modules/RelatedNews'
import Text from 'ui/components/modules/Text'
import Timeline from 'ui/components/modules/Timeline'

export default class Modules extends PureComponent {
  getModules() {
    const { post } = this.props
    return post.modules.map((module, index) => {
      const key = index + '-' + module.acfFcLayout

      switch (module.acfFcLayout) {
        case 'accordion':
          return <Accordion {...module} />

        case 'clients':
          return <Clients {...module} />

        case 'contact':
          return <Contact {...module} />

        case 'expertise':
          return <Expertise {...module} />

        case 'featureSlider':
          return <FeatureSlider {...module} />

        case 'focusLinks':
          return <FocusLinks {...module} />

        case 'gallery':
          return <Gallery {...module} />

        case 'headingText':
          return <HeadingText {...module} />

        case 'image':
          return <Image {...module} />

        case 'imageText':
          return <ImageText {...module} />

        case 'loadTypes':
          return <LoadTypes {...module} />

        case 'text':
          return <Text {...module} />

        case 'timeline':
          return <Timeline {...module} />

        case 'news':
          return <News {...module} />

        case 'relatedNews':
          return <RelatedNews {...module} />

        default:
          return (
            <Missing key={key}>
              Missing implementation of {module.acfFcLayout}
            </Missing>
          )
      }
    })
  }

  render() {
    const { post } = this.props

    if (!post) return null

    const { modules } = post

    if (!modules) return null

    const renderedModules = this.getModules()
    return (
      <Wrapper>
        {renderedModules.map((module, index) => (
          <Module
            key={index}
            index={index}
            type={modules[index].acfFcLayout}
            nextType={modules[index + 1] && modules[index + 1].acfFcLayout}
            count={modules.length}
          >
            {module}
          </Module>
        ))}
      </Wrapper>
    )
  }
}

// Styles
const Wrapper = styled.div`
  background-color: #fff;
`

const Missing = styled.div`
  padding: 30px;
  background-color: ${props => props.theme.colors.tones.light};
  color: ${props => props.theme.colors.tones.dark};
  font-size: 18px;
`
