import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <rect
          width="80"
          height="80"
          x="1"
          y="1"
          rx="14.94"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <circle
          cx="41"
          cy="50.04"
          r="4.52"
          stroke="#fff"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <rect
          width="30.74"
          height="23.74"
          x="25.63"
          y="38.17"
          rx="0.99"
          stroke="#fff"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path
          d="M31.06 38.17V30A9.94 9.94 0 0141 20.09 9.94 9.94 0 0150.94 30v8.14"
          stroke="#fff"
          fill="none"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)
