import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { responsiveText } from 'ui/styles'

const languages = [
  {
    label: 'en',
    key: 'en',
    icon: (
      <svg
        width={20}
        height={13}
        viewBox="0 0 20 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="#FFF" fillRule="nonzero">
          <path d="M8 4V0H1zM6 5L0 1v4z" />
          <path d="M10.845 5.659V0h-1.69v5.659H0V7.34h9.155V13h1.69V7.341H20V5.66z" />
          <path d="M14 8l6 4V8zM12 9v4h7zM12 0v4l7-4zM14 5h6V1zM8 9l-7 4h7zM0 8v4l6-4z" />
        </g>
      </svg>
    )
  },
  {
    label: 'da',
    key: undefined,
    icon: (
      <svg
        width={20}
        height={13}
        viewBox="0 0 20 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="#FFF" fillRule="evenodd">
          <path d="M8.616 5.25H20V0H8.616zM0 5.25h6.341V0H0zM0 13h6.341V7.75H0zM8.616 13H20V7.75H8.616z" />
        </g>
      </svg>
    )
  }
]

const LanguageSelector = () => {
  const params = useParams()
  const [expanded, setExpanded] = useState(false)

  const language = params.language

  const selected = languages.find(l => l.key === language)

  return (
    <Wrapper>
      <Language as="div" href="" onClick={() => setExpanded(!expanded)}>
        {selected.icon}
        {selected.label}
      </Language>
      <SelectWrapper
        animate={{
          width: expanded ? 'auto' : '0px',
          opacity: expanded ? 1 : 0
        }}
        transition={{ type: 'spring', damping: 20, stiffness: 100 }}
      >
        <Select>
          {languages
            .filter(l => l.key !== selected.key)
            .map(({ label, key, icon }, index) => (
              <Language href={`/${key || ''}`} key={key || 'default'}>
                {icon}
                {label}
              </Language>
            ))}
        </Select>
      </SelectWrapper>
      <Triangle expanded={expanded} onClick={() => setExpanded(!expanded)}>
        {triangleIcon}
      </Triangle>
    </Wrapper>
  )
}

export default LanguageSelector

const triangleIcon = (
  <svg
    width={6}
    height={4}
    viewBox="0 0 6 4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 4L0 0h6z" fill="#FFF" fillRule="evenodd" />
  </svg>
)

const Wrapper = styled.div`
  height: ${props => props.theme.navHeight}px;
  display: flex;
  align-items: center;
`

const Language = styled.a`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  ${responsiveText('xxs')};
  cursor: pointer;

  color: #fff;
  transition: color 500ms;

  svg {
    margin-right: 5px;
  }
`

const SelectWrapper = styled(motion.div)`
  overflow: hidden;
`

const Select = styled.div`
  display: flex;
  width: auto;

  ${Language} {
    margin-left: 10px;
  }
`

const Triangle = styled.div`
  cursor: pointer;
  padding: 10px;
  transform: rotate(${props => (props.expanded ? -90 : 90)}deg);
  transition: 500ms;
`
