import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <rect
          width="80"
          height="80"
          x="1"
          y="1"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
          rx="14.94"
        />
        <path
          fill="#fff"
          d="M40.05 64.32a1 1 0 101.9 0V58.5l.15-.15 10.46-10.46a14.17 14.17 0 000-20L41.67 17a.94.94 0 00-1.34 0L29.44 27.89a14.17 14.17 0 000 20L39.9 58.35l.15.15v5.82zM39.19 55l-8.4-8.4a12.26 12.26 0 010-17.32l9.86-9.86L41 19l.35.35 9.86 9.86a12.26 12.26 0 010 17.32L42.81 55l-.86.86v-9.8l.15-.15 7.35-7.35a1 1 0 00-1.35-1.34l-5.29 5.29-.86.86V30.12a1 1 0 10-1.9 0v13.25l-.86-.86-5.29-5.29a1 1 0 10-1.35 1.34l7.35 7.35.15.15v9.75z"
        />
      </g>
    </g>
  </svg>
)
