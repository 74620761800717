import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import responsiveText from 'ui/styles/responsiveText'
import theme from 'ui/styles/theme'
import getRelativeUrl from 'utils/getRelativeUrl'

export default class RelatedNews extends Component {
  render() {
    const { heading, posts } = this.props

    return (
      <Wrapper>
        <Container fluid>
          {heading && (
            <Row>
              <Col>
                <h2>{heading}</h2>
              </Col>
            </Row>
          )}
          <Row alignItems="start">
            {posts.map(post => (
              <Col md={4} key={post.postId}>
                <Post to={getRelativeUrl(post.link)}>
                  <Image image={post.featuredImage.sourceUrl} />
                  <Date>
                    <Dot />
                    <span>{dayjs(post.date).format('DD. MMMM YYYY')}</span>
                  </Date>
                  <h2 dangerouslySetInnerHTML={{ __html: post.title }} />
                  <p>{post.excerpt}</p>
                </Post>
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>
    )
  }
}

export const query = `
  heading
  posts {
    title
    link
    date
    excerpt
    postId
    featuredImage {
      sourceUrl
    }
  }
`

// Styles
const { colors } = theme

const Wrapper = styled.div``

const Post = styled(Link)`
  margin-bottom: 3rem;
  display: block;

  h2 {
    ${responsiveText('md')};
    margin-top: 0.5rem;
  }
`

const Date = styled.div`
  display: flex;
  align-items: center;

  span {
    ${responsiveText('xs')};
    font-weight: bold;
    text-transform: uppercase;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.primary};
  border-radius: 100%;
  margin-right: 0.5rem;
`

const Image = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-image: url(${props => props.image});
  margin-bottom: 0.5rem;
  background-size: cover;
  background-position: center;
`
