import React, { useRef, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import useEventListener from '@use-it/event-listener'
import VideoCover from 'react-video-cover'
import {
  AnimatePresence,
  motion,
  useViewportScroll,
  useTransform,
  useSpring
} from 'framer-motion'

import {
  PaddedContent,
  IconAmbient,
  IconChilled,
  IconGreen,
  IconHighValue,
  IconSnowflake,
  ScrollIndicator
} from 'ui/components/generic'
import { responsiveText } from 'ui/styles'
import theme from 'ui/styles/theme'

const getIcon = icon => {
  switch (icon) {
    case 'snowflake':
      return <IconSnowflake />
    case 'highValue':
      return <IconHighValue />
    case 'green':
      return <IconGreen />
    case 'chilled':
      return <IconChilled />
    case 'ambient':
      return <IconAmbient />
    default:
      return null
  }
}

export default ({ post }) => {
  // define refs
  const background = useRef(null)
  const line1 = useRef(null)
  const line2 = useRef(null)

  const [showVideo, setShowVideo] = useState(true)

  const { scrollY } = useViewportScroll()

  const springConfig = { stiffness: 400, damping: 90 }

  const line1X = useSpring(
    useTransform(scrollY, scrollY => -scrollY),
    springConfig
  )
  const line2X = useSpring(
    useTransform(scrollY, scrollY => scrollY),
    springConfig
  )

  const scrollOffset = 200

  const backgroundY = useTransform(scrollY, scrollY =>
    scrollY > scrollOffset ? -(scrollY - scrollOffset) / 0.4 : 0
  )

  // handle showing and hiding of video
  const handleScroll = useCallback(() => {
    if (window.pageYOffset < window.innerHeight) {
      // show video if hidden
      if (!showVideo) {
        setShowVideo(true)
      }
    } else {
      // hide video if visible
      if (showVideo) {
        setShowVideo(false)
      }
    }
  }, [showVideo, setShowVideo])

  useEventListener('scroll', handleScroll)

  if (!post || !post.header) {
    return null
  }

  const {
    header: {
      type,
      textLine1,
      textLine2,
      mediaType,
      image,
      video,
      subHeader,
      icon,
      disableGradient
    }
  } = post

  return (
    <Wrapper type={type}>
      <Fixed ref={background} type={type} y={backgroundY}>
        <AnimatePresence>
          <Background
            key={`media-${post.id}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: 'tween' }}
            image={mediaType === 'image' && image?.sourceUrl}
            type={type}
            disableGradient={disableGradient}
          >
            {mediaType === 'video' && video && showVideo && (
              <VideoCover
                videoOptions={{
                  src: video.mediaItemUrl,
                  autoPlay: true,
                  playsInline: true,
                  loop: true,
                  muted: true
                }}
              />
            )}
          </Background>
        </AnimatePresence>
        <PaddedContent size="xLarge">
          <AnimatePresence exitBeforeEnter>
            {type === 'hero' && subHeader && (
              <motion.div
                key={subHeader}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ type: 'tween', duration: 0.5 }}
              >
                <SubHeader dangerouslySetInnerHTML={{ __html: subHeader }} />
              </motion.div>
            )}
            {icon && icon !== 'none' && (
              <motion.div
                key={icon}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ type: 'tween', duration: 0.5 }}
              >
                <Icon>{getIcon(icon)}</Icon>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={textLine1}
              initial={{ x: 20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -20, opacity: 0 }}
              transition={{ type: 'tween', duration: 0.5 }}
            >
              <Line
                dangerouslySetInnerHTML={{ __html: textLine1 }}
                ref={line1}
                align={type === 'hero' ? 'left' : 'center'}
                type={type}
                x={line1X}
              />
            </motion.div>
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={textLine2}
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 20, opacity: 0 }}
              transition={{ type: 'tween', duration: 0.5 }}
            >
              <Line2
                dangerouslySetInnerHTML={{ __html: textLine2 }}
                ref={line2}
                align={type === 'hero' ? 'right' : 'center'}
                type={type}
                x={line2X}
              />
            </motion.div>
          </AnimatePresence>
        </PaddedContent>
        <Scroll />
      </Fixed>
    </Wrapper>
  )
}

// Styles
const { colors } = theme

const Wrapper = styled.div`
  text-align: center;
  height: 85vh;
  position: relative;
  z-index: 10;

  background-color: ${props =>
    props.type === 'hero' ? colors.tones.lightest : 'transparent'};
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  ${props =>
    props.image &&
    css`
    background-image: url('${props => props.image}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `}

  &:after {
    content: '';
    display: ${props => (props.disableGradient ? 'none' : 'block')};
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      rgba(0, 50, 205, 0) 65%,
      #00007d 95%
    );
    opacity: ${props => (props.type === 'pageHeader' ? 1 : 0)};
    pointer-events: none;
    transition: opacity 500ms;
  }
`

const Fixed = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.type === 'pageHeader' &&
    css`
      align-items: flex-end;
      padding-bottom: 130px;
    `}
`

const Line = styled(motion.h1)`
  color: #fff;
  text-transform: uppercase;
  text-align: ${props => props.align};
  ${props => responsiveText(props.type === 'hero' ? 'xxl' : 'xl')}
  margin-bottom: 0;
`

const Line2 = styled(Line)`
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
`

const SubHeader = styled.h2`
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin-bottom: 80px;
  text-align: center;
  ${props => responsiveText('xs')}
  color: #fff;
`

const Icon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 100%;
  left: 50%;
  margin-left: -40px;
  margin-bottom: 40px;
  width: 80px;
  height: 80px;

  svg {
    margin: auto;
  }
`

const Scroll = styled(ScrollIndicator)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`
