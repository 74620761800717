import { SET_POST } from '../actions'

export default (state = { post: null }, action) => {
  switch (action.type) {
    case SET_POST:
      return { ...state, post: action.payload }
    default:
      return state
  }
}

export const setPost = post => ({
  type: SET_POST,
  payload: post
})

export const hasSubNavigation = post =>
  post && (post.parent || post.childPages.nodes.length > 0)
