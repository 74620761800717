import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'
import { responsiveText } from 'ui/styles/index'
import { Link } from 'react-router-dom'

import theme from 'ui/styles/theme'
import easing from 'ui/styles/easing'
import { getRelativeUrl } from 'utils/index'

const { colors } = theme

export default ({ heading, expertise }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col>
            <h2>{heading}</h2>
          </Col>
        </Row>
        <Row alignItems="stretch">
          {expertise.length <= 4
            ? expertise.map((item, index) => (
                <Col sm={12 / expertise.length} key={`expertise-${index}`}>
                  <Content>
                    <Icon>
                      <img src={item.icon.sourceUrl} alt="" />
                    </Icon>
                    <Title>{item.title}</Title>
                    <Text>{item.text}</Text>
                    {item.link && item.linkLabel && (
                      <Link to={getRelativeUrl(item.link)}>
                        {item.linkLabel}
                      </Link>
                    )}
                  </Content>
                </Col>
              ))
            : expertise.map((item, index) => (
                <Col sm={3} key={`expertise-${index}`}>
                  <Content>
                    <Icon>
                      <img src={item.icon.sourceUrl} alt="" />
                    </Icon>
                    <Title>{item.title}</Title>
                    <Text>{item.text}</Text>
                    {item.link && item.linkLabel && (
                      <Link to={getRelativeUrl(item.link)}>
                        {item.linkLabel}
                      </Link>
                    )}
                  </Content>
                </Col>
              ))}
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  expertise {
    icon {
      sourceUrl
    }
    title
    text
    linkLabel
    link
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  text-align: center;
  padding: 30px 0;

  ${media.sm`
    padding: 90px 0;
  `}

  h2 {
    margin-bottom: 2em;
  }

  a {
    ${responsiveText('xs')};
    color: ${colors.tones.medium};
    transition: color 0.3s ${easing.easeOutCubic};

    &:hover {
      color: ${colors.primaryDark};
    }
  }
`

const Content = styled.div`
  margin-bottom: 50px;

  ${media.sm`
    margin-bottom: 20px;
  `}
`

const Icon = styled.div`
  background: ${colors.tones.lightest};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
`

const Title = styled.h3`
  ${responsiveText('sm')};
  color: ${colors.primaryDark};
  margin-bottom: 0.25em;
`

const Text = styled.p`
  color: ${colors.tones.medium};
`
