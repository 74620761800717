import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'
import responsiveText from 'ui/styles/responsiveText'
import theme from 'ui/styles/theme'

export default ({ text }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col sm={6} smOffset={3}>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  text
`

// Styles
const { colors } = theme

const Wrapper = styled.section`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
`

const Text = styled.div`
  ${responsiveText('sm')};

  p,
  ul,
  ol {
    font-size: inherit;
    line-height: 1.25em;
    color: ${colors.textLight};
  }

  h1 {
    font-size: 4rem;
    line-height: 1.25em;
  }

  blockquote {
    padding-left: 4rem;
    margin: 0;
    position: relative;
    font-size: 4rem;

    > * {
      ${responsiveText('md')};
      color: ${colors.text};
    }

    &:before {
      content: '“';
      display: block;
      position: absolute;
      top: 1rem;
      left: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .alignleft {
    float: left;
    margin-right: 1em;
  }

  .alignright {
    float: right;
    margin-left: 1em;
  }

  .aligncenter {
    margin: 0 auto;
  }
`
