import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useEventListener from '@use-it/event-listener'

import useMainNav from 'containers/useMainNav'
import { Logo, LogoCompact, MenuIcon, Button } from 'ui/components/generic'
import Overlay from './Overlay'
import LanguageSelector from './LanguageSelector'
import theme from 'ui/styles/theme'
import getRelativeUrl from 'utils/getRelativeUrl'

export default () => {
  const [overlayVisible, setOverlayVisible] = useState(false)
  const { loading, data: mainNav } = useMainNav()

  const location = useLocation()

  const [scrolledDown, setScrolledDown] = useState(false)

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 300) {
      if (!scrolledDown) {
        setScrolledDown(true)
      }
    } else {
      if (scrolledDown) {
        setScrolledDown(false)
      }
    }
  }, [scrolledDown])

  useEventListener('scroll', handleScroll)

  // close overlay when navigation changes
  useEffect(() => {
    if (overlayVisible) {
      setOverlayVisible(false)
    }
  }, [location.pathname]) // eslint-disable-line

  const toggleOverlay = useCallback(() => {
    setOverlayVisible(!overlayVisible)
  }, [overlayVisible, setOverlayVisible])

  return (
    <>
      <Overlay visible={overlayVisible} />
      <Wrapper scrolledDown={scrolledDown}>
        <Container fluid>
          <Row>
            <Col xs={4} sm={4}>
              <LogoWrapper to="/">
                <LogoDesktop />
                <LogoMobile />
              </LogoWrapper>
            </Col>
            <Col xs={4} sm={4}>
              <MenuContainer overlayVisible={overlayVisible}>
                <MenuIcon toggle={toggleOverlay} open={overlayVisible} />
              </MenuContainer>
            </Col>
            <Col xs={4} sm={4}>
              <ActionsContainer>
                {!loading && mainNav?.topBarLink?.show && (
                  <TopBarButton to={getRelativeUrl(mainNav.topBarLink.link)}>
                    {mainNav.topBarLink.text}
                  </TopBarButton>
                )}
                <LanguageSelector />
              </ActionsContainer>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

// Styles
const { colors, navHeight } = theme

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${navHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 101;
  background-color: ${props =>
    props.scrolledDown ? colors.primaryDark : 'transparent'};
  transition: 500ms;
`

const LogoWrapper = styled(Link)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;

  svg {
    max-width: 277px;

    path {
      fill: white;
    }
  }
`

const LogoMobile = styled(LogoCompact)`
  ${media.md`
    display: none;
  `}
`

const LogoDesktop = styled(Logo)`
  display: none;

  ${media.md`
    display: block;
  `}
`

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`

const TopBarButton = styled(Button).attrs({
  color: 'secondary',
  size: 'small'
})`
  margin-right: 10px;
  text-transform: uppercase;
  height: auto;
  display: none;
  padding: 4px 20px;

  ${media.md`
    display: inline-block;
  `}

  &&:hover {
    background: transparent;
    border-color: #fff;
    color: #fff;
  }
`
