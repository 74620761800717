import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import responsiveText from 'ui/styles/responsiveText'

const Wrapper = styled.div`
  height: ${props =>
    props.size === 'large' ? 50 : props.size === 'small' ? 20 : 30}px;
  min-width: ${props => (props.size === 'large' ? 200 : 100)}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 20px;
  ${props => responsiveText(props.size === 'small' ? 'xs' : 'sm')};

  border: 1px solid
    ${props =>
      props.color
        ? props.theme.colors[props.color] || props.color
        : props.theme.colors.primaryDark};

  background-color: ${props =>
    props.color
      ? props.theme.colors[props.color] || props.color
      : props.theme.colors.primaryDark};

  ${props =>
    props.negative &&
    css`
      border: 1px solid ${props => props.theme.colors.tones.light};
      background-color: #fff;
    `}

  transition: all 400ms;

  & + a {
    margin-left: 10px !important;
  }

  &&,
  &&:active,
  &&:visited {
    color: #fff;

    ${props =>
      props.negative &&
      css`
        color: ${props =>
          props.color
            ? props.theme.colors[props.color] || props.color
            : props.theme.colors.primaryDark};
      `}

    text-decoration: none;
  }

  &:hover {
    background-color: #fff;
    color: ${props =>
      props.color
        ? props.theme.colors[props.color] || props.color
        : props.theme.colors.primaryDark};

    ${props =>
      props.negative &&
      css`
        border: 1px solid
          ${props =>
            props.color
              ? props.theme.colors[props.color] || props.color
              : props.theme.colors.primaryDark};

        background-color: ${props =>
          props.color
            ? props.theme.colors[props.color] || props.color
            : props.theme.colors.primaryDark};

        color: #fff;
      `}
  }
`

class Button extends PureComponent {
  static defaultProps = {
    hollow: false,
    component: Link
  }

  render() {
    const { component, ...rest } = this.props
    return <Wrapper as={component} {...rest} />
  }
}

export default Button
