import React, { PureComponent } from 'react'
import ReactMDSpinner from 'react-md-spinner'

import theme from 'ui/styles/theme'

class Spinner extends PureComponent {
  render() {
    return (
      <ReactMDSpinner singleColor={theme.colors.primaryDark} {...this.props} />
    )
  }
}

export default Spinner
