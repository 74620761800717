import { gql } from '@apollo/client'
import useLocalizedQuery from 'containers/useLocalizedQuery'

export default () => {
  const { data, loading, error } = useLocalizedQuery(gql`
    query Redirects {
      optionsRedirects {
        redirects {
          from
          to
        }
      }
    }
  `)

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching redirects', error)
  }

  return { redirects: data?.optionsRedirects?.redirects, loading, error }
}
