import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { responsiveText } from 'ui/styles'
import getRelativeUrl from 'utils/getRelativeUrl'
import useScrollEntry from 'utils/useScrollEntry'
import { Button } from 'ui/components/generic'

import theme from 'ui/styles/theme'

const { colors } = theme

export default ({ alignment, image, heading, text, link, linkText }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row alignItems="center">
          <Col sm={4} smOffset={1} order={alignment === 'left' ? 2 : 1}>
            <h2>{heading}</h2>
            <Text dangerouslySetInnerHTML={{ __html: text }} />
            {link && linkText && (
              <Button to={getRelativeUrl(link)} negative>
                {linkText}
              </Button>
            )}
          </Col>
          <Col sm={5} smOffset={1} order={alignment === 'left' ? 1 : 2}>
            <img src={image.sourceUrl} alt="" />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  alignment
  image {
    sourceUrl
  }
  heading
  text
  link
  linkText
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;

  ${media.sm`
    margin-bottom: 150px;
  `}

  img {
    width: 100%;
    margin-bottom: 40px;
  }

  a {
    margin-bottom: 40px;
  }
`

const Text = styled.div`
  ${responsiveText('sm')};
  color: ${colors.tones.medium};
  margin-bottom: 40px;
`
