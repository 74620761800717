import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import usePosts from 'containers/usePosts'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import Spinner from 'ui/components/generic/Spinner'
import dayjs from 'dayjs'
import responsiveText from 'ui/styles/responsiveText'
import theme from 'ui/styles/theme'
import getRelativeUrl from 'utils/getRelativeUrl'

const News = () => {
  const [page, setPage] = useState(1)
  const newsContainer = useRef(null)

  const { data: posts, loading, pagination } = usePosts(8, page)

  const prevPage = () => {
    setPage(page - 1)
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  return (
    <Wrapper ref={newsContainer}>
      {loading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}

      {!loading && (
        <Container fluid>
          <Row alignItems="start">
            {posts.map((post, index) => (
              <Col sm={index ? 6 : 12} md={index ? 4 : 8} key={post.postId}>
                <Post to={getRelativeUrl(post.link)}>
                  <Image image={post.featuredImage.sourceUrl} />
                  <Date>
                    <Dot />
                    <span>{dayjs(post.date).format('DD. MMMM YYYY')}</span>
                  </Date>
                  <h2 dangerouslySetInnerHTML={{ __html: post.title }} />
                  <p>{post.excerpt}</p>
                </Post>
              </Col>
            ))}
          </Row>
          {pagination.total > 8 && (
            <Row>
              <Col>
                <Pagination>
                  <Button
                    disabled={!pagination.hasPrevious}
                    onClick={() => prevPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="7"
                      viewBox="0 0 21 7"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path className="fill" d="M0 3.5L5 0v7z"></path>
                        <path
                          className="stroke"
                          strokeLinecap="square"
                          d="M5.5 3.5h15"
                        ></path>
                      </g>
                    </svg>
                  </Button>

                  <span>
                    {page} / {Math.ceil(pagination.total / 8)}
                  </span>

                  <Button
                    disabled={!pagination.hasMore}
                    onClick={() => nextPage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="7"
                      viewBox="0 0 21 7"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path className="fill" d="M21 3.5L16 7V0z"></path>
                        <path
                          className="stroke"
                          strokeLinecap="square"
                          d="M15.5 3.5H.5"
                        ></path>
                      </g>
                    </svg>
                  </Button>
                </Pagination>
              </Col>
            </Row>
          )}
        </Container>
      )}
    </Wrapper>
  )
}

export default News

export const query = ``

// Styles
const { colors } = theme

const Wrapper = styled.div``

const SpinnerContainer = styled.div`
  padding: 5rem 0;
  text-align: center;
`

const Post = styled(Link)`
  margin-bottom: 3rem;
  display: block;

  h2 {
    ${responsiveText('md')};
    margin-top: 0.5rem;
  }
`

const Date = styled.div`
  display: flex;
  align-items: center;

  span {
    ${responsiveText('xs')};
    font-weight: bold;
    text-transform: uppercase;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${colors.primary};
  border-radius: 100%;
  margin-right: 0.5rem;
`

const Image = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-image: url(${props => props.image});
  margin-bottom: 0.5rem;
  background-size: cover;
  background-position: center;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: ${colors.primary};
  }
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.disabled
      ? `
    svg path {
      &.fill {
        fill: ${colors.tones.medium};
      }

      &.stroke {
        stroke: ${colors.tones.medium};
      }
    }
  `
      : `
    svg path {
      &.fill {
        fill: ${colors.primary};
      }

      &.stroke {
        stroke: ${colors.primary};
      }
    }
  `}
`
