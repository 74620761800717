import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'
import theme from 'ui/styles/theme'

export default ({ heading, image, mobileImage }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)
  const [isMobile, setIsMobile] = useState(false)

  const checkSize = useCallback(() => {
    const width = window.innerWidth

    const sizeIsMobile = width <= theme.grid.breakpoints.md

    if (sizeIsMobile && !isMobile) {
      setIsMobile(true)
    } else if (!sizeIsMobile && isMobile) {
      setIsMobile(false)
    }
  }, [isMobile])

  useEffect(() => {
    checkSize()

    window.addEventListener('resize', checkSize)

    return () => {
      window.removeEventListener('resize', checkSize)
    }
  }, [checkSize])

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col sm={10} smOffset={1}>
            <h2>{heading}</h2>
          </Col>
          <Col>
            <img
              src={
                isMobile && mobileImage
                  ? mobileImage.sourceUrl
                  : image.sourceUrl
              }
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  image {
    sourceUrl
  }
  mobileImage {
    sourceUrl
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  text-align: center;
  margin-bottom: 50px;

  ${media.sm`
    margin-bottom: 150px;
  `}

  img {
    width: 100%;
  }
`
