import React, { PureComponent } from 'react'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.z || 9999};
  pointer-events: none;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 500ms;

  &:after {
    display: block;
    content: "Breakpoint: xs";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: ${props => props.theme.colors.primaryDark};
    padding: 10px 20px;
    line-height: 10px;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    border-top-right-radius: 10px;

    ${media.sm`
      content: "Breakpoint: sm"
    `}

    ${media.md`
      content: "Breakpoint: md"
    `}

    ${media.lg`
      content: "Breakpoint: lg"
    `}

    ${media.xl`
      content: "Breakpoint: xl"
    `}
  }
`

const StyledRow = styled(Row)`
  height: 100%;
`

const WhiteCol = styled.div`
  background: ${props => props.theme.colors.primaryDark};
  opacity: 0.25;
  height: 100%;
`

export default class GridView extends PureComponent {
  state = { visible: false }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = e => {
    if (e.ctrlKey && e.key === 'g') {
      this.setState(state => ({ visible: !state.visible }))
    }
  }

  render() {
    const { z } = this.props
    const { visible } = this.state

    return (
      <StyledContainer fluid z={z} visible={visible}>
        <StyledRow>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
          <Col col="1">
            <WhiteCol />
          </Col>
        </StyledRow>
      </StyledContainer>
    )
  }
}
