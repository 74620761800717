export default {
  displayFont: 'Aeonik, sans-serif',
  bodyFont: 'Aeonik, sans-serif',
  fontSizes: {
    xxl: {
      xs: {
        fs: 50,
        lh: 45
      },
      sm: {
        fs: 80,
        lh: 70
      },
      md: {
        fs: 110,
        lh: 100
      },
      lg: {
        fs: 140,
        lh: 115
      },
      xl: {
        fs: 160,
        lh: 125
      }
    },
    xl: {
      xs: {
        fs: 50,
        lh: 45
      },
      sm: {
        fs: 60,
        lh: 50
      },
      md: {
        fs: 80,
        lh: 70
      },
      lg: {
        fs: 120,
        lh: 100
      },
      xl: {
        fs: 120,
        lh: 100
      }
    },
    lg: {
      xs: {
        fs: 40,
        lh: 42
      },
      sm: {
        fs: 40,
        lh: 42
      },
      md: {
        fs: 40,
        lh: 42
      },
      lg: {
        fs: 40,
        lh: 42
      },
      xl: {
        fs: 40,
        lh: 42
      }
    },
    md: {
      xs: {
        fs: 30,
        lh: 32
      },
      sm: {
        fs: 30,
        lh: 32
      },
      md: {
        fs: 30,
        lh: 32
      },
      lg: {
        fs: 30,
        lh: 32
      },
      xl: {
        fs: 30,
        lh: 32
      }
    },
    sm: {
      xs: {
        fs: 18,
        lh: 21
      },
      sm: {
        fs: 18,
        lh: 21
      },
      md: {
        fs: 18,
        lh: 21
      },
      lg: {
        fs: 18,
        lh: 21
      },
      xl: {
        fs: 18,
        lh: 21
      }
    },
    xs: {
      xs: {
        fs: 14,
        lh: 16
      },
      sm: {
        fs: 14,
        lh: 16
      },
      md: {
        fs: 14,
        lh: 16
      },
      lg: {
        fs: 14,
        lh: 16
      },
      xl: {
        fs: 14,
        lh: 16
      }
    },
    xxs: {
      xs: {
        fs: 12,
        lh: 14
      },
      sm: {
        fs: 12,
        lh: 14
      },
      md: {
        fs: 12,
        lh: 14
      },
      lg: {
        fs: 12,
        lh: 14
      },
      xl: {
        fs: 12,
        lh: 14
      }
    }
  },
  fontWeights: {
    normal: 400,
    bold: 900
  },
  spacing: {
    xs: 10,
    sm: 15,
    md: 15,
    lg: 25,
    xl: 25
  },
  colors: {
    tones: {
      lightest: '#F5F5F5',
      lighter: '#ECEBEB',
      light: '#C8C8C8',
      medium: '#B4B4B4',
      dark: '#303030',
      darkest: '#000000'
    },
    primary: '#0032CD',
    primaryAlt: '#002DB9',
    primaryDark: '#00007D',

    secondary: '#28D778',

    background: '#EEEEEA',
    text: '#000',
    textLight: '#646464'
  },
  borderRadius: 4,
  navHeight: 60,
  grid: {
    breakpoints: {
      // defaults below
      giant: 1240,
      xl: 1240,
      desktop: 1080,
      lg: 1080,
      tablet: 768,
      md: 768,
      phone: 576,
      sm: 576,
      smaller: 575,
      xs: 575
    },
    row: {
      padding: 10 // default 15
    },
    col: {
      padding: 10 // default 15
    },
    container: {
      padding: 50, // default 15
      paddingXs: 10
    }
  }
}
