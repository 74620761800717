import { gql } from '@apollo/client'

import useLocalizedQuery from 'containers/useLocalizedQuery'

export default () => {
  const { data, loading, error } = useLocalizedQuery(gql`
    query mainNav {
      optionsNav {
        groups {
          groupTitle
          menuItems {
            hasSubMenu
            link
            externalLink
            title
            submenuItems {
              label
              link
              externalSubLink
              image {
                sourceUrl
              }
            }
          }
        }
        topBarLink {
          show
          text
          link
        }
      }
    }
  `)

  if (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching main nav', error)
  }

  return { data: data?.optionsNav, loading, error }
}
