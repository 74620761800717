import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { media } from 'styled-bootstrap-grid'

import getRelativeUrl from 'utils/getRelativeUrl'
import useMainNav from 'containers/useMainNav'
import responsiveText from 'ui/styles/responsiveText'

import theme from 'ui/styles/theme'
import easing from 'ui/styles/easing'

export default ({ visible }) => {
  const { data: optionsNav, loading } = useMainNav()
  const [subMenu, setSubMenu] = useState(null)
  const [hoverIndex, setHoverIndex] = useState(0)
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0

  if (loading) return null

  const { groups } = optionsNav

  const triggerSubMenu = (image, items) => {
    if (subMenu && items === subMenu.items) {
      setSubMenu(null)
    } else {
      setSubMenu({
        image,
        items,
        visible: true
      })
    }
  }

  return (
    <Wrapper visible={visible}>
      <Nav visible={visible && !loading}>
        <Content>
          <MainNav subNavVisible={subMenu && subMenu.visible}>
            {groups.map((group, index) => (
              <Group key={index}>
                <GroupTitle>{group.groupTitle}</GroupTitle>
                <MenuItems>
                  {group.menuItems.map((menuItem, index) => {
                    return menuItem.hasSubMenu ? (
                      <button
                        onClick={() =>
                          triggerSubMenu(menuItem.image, menuItem.submenuItems)
                        }
                        key={index}
                      >
                        {menuItem.title}
                      </button>
                    ) : (
                      <div key={index}>
                        {menuItem.externalLink ? (
                          <a
                            href={getRelativeUrl(menuItem.link)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {menuItem.title}
                          </a>
                        ) : (
                          <Link to={getRelativeUrl(menuItem.link)}>
                            {menuItem.title}
                          </Link>
                        )}
                      </div>
                    )
                  })}
                </MenuItems>
              </Group>
            ))}
          </MainNav>
          {subMenu && subMenu.visible && (
            <AnimatePresence>
              <SubMenu
                initial={{
                  opacity: windowWidth > 575 ? 0 : 1,
                  x: windowWidth > 575 ? 100 : 0
                }}
                animate={{ opacity: 1, x: windowWidth > 575 ? 0 : '-100%' }}
                exit={{ opacity: 0 }}
                key="subMenu"
                visible={subMenu && subMenu.visible}
              >
                <SubmenuItems>
                  <button onClick={() => setSubMenu(null)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                  </button>
                  <GroupTitle dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
                  {subMenu.items.map((item, index) => (
                    <div key={index} onMouseEnter={() => setHoverIndex(index)}>
                      {item.externalSubLink ? (
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.label}
                        </a>
                      ) : (
                        <Link to={getRelativeUrl(item.link)}>{item.label}</Link>
                      )}
                    </div>
                  ))}
                </SubmenuItems>
                <SubmenuImage>
                  {subMenu.items.map(
                    (item, index) =>
                      item.image && (
                        <NavImage
                          src={item.image.sourceUrl}
                          alt=""
                          key={index}
                          visible={index === hoverIndex}
                        />
                      )
                  )}
                </SubmenuImage>
              </SubMenu>
            </AnimatePresence>
          )}
        </Content>
      </Nav>
    </Wrapper>
  )
}

const { navHeight, colors, grid } = theme

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: ${navHeight}px 0 0;
  -webkit-overflow-scrolling: touch;
  background-color: ${colors.primaryDark};
  color: #fff;
  opacity: ${props => (props.visible ? 1 : 1)};
  transform: translateY(${props => (props.visible ? 0 : -100)}%);
  z-index: 11;
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};
  transition: all 800ms;
  overflow: hidden;
`

const Nav = styled.div`
  display: flex;
  height: 100%;

  -webkit-overflow-scrolling: touch;

  a,
  button {
    ${responsiveText('md')};
    color: white;
    transition: color 0.3s ${easing.easeOutCubic};
    line-height: normal !important;

    &:hover {
      color: ${colors.secondary};
    }
  }

  button {
    padding: 0;
    margin: 0;
    background: 0;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
`

const Content = styled.div`
  max-height: 100%;
  width: 100%;
  padding-top: 60px;
  display: flex;

  ${media.xs`
    padding-top: 20px;
  `}
`

const MainNav = styled.div`
  width: 25%;
  margin-left: 5rem;
  padding-top: 5rem;

  ${media.xs`
    padding: 0 ${grid.container.paddingXs}px;
    margin-left: 0;
    width: 100%;
    transform: ${props => (props.subNavVisible ? 'translateX(-100%)' : 'none')};
    flex-shrink: 0;
    transition: transform 0.5s ${easing.easeOutCubic};
  `}
`

const Group = styled.div`
  margin-bottom: 60px;
`

const GroupTitle = styled.h4`
  ${responsiveText('xxs')};
  text-transform: uppercase;
`

const MenuItems = styled.div``

const SubMenu = styled(motion.div)`
  background: #00005a;
  width: 75%;
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;

  a {
    color: white;
  }

  ${media.xs`
    width: 100%;
    flex-shrink: 0;
    padding-top: 0;
    transform: ${props =>
      props.visible ? 'translateX(-100%)' : 'translateX(0)'};
    transition: transform 0.5s ${easing.easeOutCubic};
  `}
`

const SubmenuItems = styled.div`
  width: 35%;
  padding-left: 5rem;

  ${media.xs`
    width: 100%;
    padding: 20px ${grid.container.paddingXs}px 0;
  `}

  button {
    width: 30px;
    height: 30px;
    color: ${colors.primaryDark};
    display: none;

    ${media.xs`
      display: block;
    `}
  }
`

const SubmenuImage = styled.div`
  width: 65%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  ${media.xs`
    display: none;
  `}
`

const NavImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ${easing.easeOutCubic};
`
