import React from 'react'
import styled, { keyframes } from 'styled-components'

export default props => (
  <Svg
    width={27}
    height={42}
    viewBox="0 0 27 42"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <g id="one">
        <path fill="#FFF" d="M13 30.5l-3.5-5h7z" />
        <path stroke="#FFF" strokeLinecap="square" d="M13 25V10" />
      </g>
      <g id="two">
        <path fill="#FFF" d="M13 30.5l-3.5-5h7z" />
        <path stroke="#FFF" strokeLinecap="square" d="M13 25V10" />
      </g>
      <rect stroke="#FFF" width={25} height={40} rx="12.5" />
    </g>
  </Svg>
)

const entry = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  33% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`

const exit = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  33% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`

const Svg = styled.svg`
  #one {
    animation: ${entry} 3s forwards infinite;
  }

  #two {
    animation: ${exit} 3s forwards infinite;
  }
`
