import React from 'react'

export default props => (
  <svg
    width="59"
    height="55"
    viewBox="0 0 59 55"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g stroke="#F5F5F5" stroke-width="2" fill="none" fill-rule="evenodd">
      <path d="M29 0v54.166M58.035 27H0M50 8L9 46M50 46L9 8" />
    </g>
  </svg>
)
