import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.19 89.24" {...props}>
    <g>
      <g>
        <path
          d="M51.63 81H15.94A14.94 14.94 0 011 66.06V15.94A14.94 14.94 0 0115.94 1h50.12A14.94 14.94 0 0181 15.94V53.4m-7.58 6.41v29.43m15.77-14.76H57.66"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <path
          fill="#fff"
          d="M33.44 25.45A16.14 16.14 0 0142 23.2a14.77 14.77 0 019.3 2.9 13.32 13.32 0 014.85 8.15h-2.7a11.08 11.08 0 00-4.15-6.33 12.3 12.3 0 00-7.45-2.22 12.55 12.55 0 00-7 1.95 12.93 12.93 0 00-4.65 5.42A18.28 18.28 0 0028.54 41a18.28 18.28 0 001.65 7.93 12.93 12.93 0 004.65 5.42 12.55 12.55 0 007 1.95 12.24 12.24 0 007.48-2.2 11.15 11.15 0 004.07-6.4h2.7a13.7 13.7 0 01-4.82 8.2A14.53 14.53 0 0142 58.8a16.31 16.31 0 01-8.55-2.22 14.78 14.78 0 01-5.7-6.28 20.78 20.78 0 01-2-9.3 20.66 20.66 0 012-9.25 14.89 14.89 0 015.69-6.3z"
        ></path>
        <circle
          cx="17.64"
          cy="24.72"
          r="5.6"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></circle>
      </g>
    </g>
  </svg>
)
