import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'

import SwiperCore, { Navigation, Pagination, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import theme from 'ui/styles/theme'
import { responsiveText } from 'ui/styles/index'

SwiperCore.use([Navigation, Pagination, Controller])

const { colors } = theme

export default ({ heading, images }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)
  const [controlledSwiper, setControlledSwiper] = useState(null)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col>
            <Swiper
              slidesPerView={1}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next'
              }}
              pagination={{
                type: 'fraction',
                el: '.gallery-pagination'
              }}
              spaceBetween={20}
              controller={{ control: controlledSwiper }}
            >
              <ControlPrev className="swiper-prev" />
              <ControlNext className="swiper-next" />
              {images.map(item => (
                <SwiperSlide>
                  <Image image={item.image.sourceUrl} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
        <Row>
          <Col sm={5} smOffset={1}>
            <GalleryPagination className="gallery-pagination" />
          </Col>
          <Col sm={5}>
            <Swiper
              slidesPerView={1}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next'
              }}
              spaceBetween={20}
              onSwiper={setControlledSwiper}
              allowSlidePrev={false}
              allowSlideNext={false}
            >
              {images.map(item => (
                <SwiperSlide>
                  {item.text && <Text>{item.text}</Text>}
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  images {
    image {
      sourceUrl
    }
    text
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  margin-bottom: 50px;

  ${media.sm`
    padding: 40px 0;
    margin-bottom: 150px;
  `}

  img {
    width: 100%;
    border-radius: 20px;
  }
`

const Text = styled.div`
  ${responsiveText('xxs')};
  color: ${colors.tones.medium};
  margin-top: 20px;
`

const GalleryPagination = styled.div`
  ${responsiveText('xxs')};
  margin-top: 20px;
  color: ${colors.primaryDark};

  .swiper-pagination-total {
    color: ${colors.tones.medium};
  }
`

const Control = styled.button`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  background: none;
  border: none;
  outline: none;
  z-index: 1;
`

const ControlPrev = styled(Control)`
  left: 0;

  &:hover {
    cursor: url('/images/hover-prev.svg') 40 40, w-resize;
  }
`

const ControlNext = styled(Control)`
  right: 0;

  &:hover {
    cursor: url('/images/hover-next.svg') 40 40, e-resize;
  }
`

const Image = styled.div`
  background: url(${props => props.image}) center / cover;
  border-radius: 20px;
  height: 0;
  padding-bottom: 100%;

  ${media.sm`
    padding-bottom: 56.25%;
  `}
`
