import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Redirect, useParams } from 'react-router-dom'

import usePost from 'containers/usePost'
import useSiteMeta from 'containers/useSiteMeta'
import Modules from 'ui/components/Modules'
import Seo from 'ui/components/Seo'
import Header from 'ui/components/Header'
import NotFound from 'ui/components/NotFound'
import { Loading } from 'ui/components/generic'
import scrollTo from 'utils/scrollTo'

function Page() {
  const { slug } = useParams()

  const { data: siteMeta } = useSiteMeta()
  const { data: post, loading, error } = usePost(
    slug || (siteMeta && siteMeta.frontPageSlug) || 'front'
  )

  useEffect(() => {
    scrollTo(document.body, 500)
  }, [slug])

  if (error) {
    return <NotFound />
  }

  return (
    <>
      {!loading && !post && (
        <Redirect
          to={siteMeta.notFoundSlug === '/front' ? '/' : siteMeta.notFoundSlug}
        />
      )}
      <Header post={post} key="header" />
      <PageContent>
        <AnimatePresence exitBeforeEnter>
          {post && (
            <motion.div
              initial={{
                x: 0,
                opacity: 0
              }}
              animate={{ x: 0, opacity: 1, transition }}
              exit={{
                x: -100,
                opacity: 0,
                transition
              }}
              key={slug}
            >
              <Wrapper>
                {loading && <Loading />}
                {!loading && <Seo seo={post?.seo} />}
                {!loading && post.modules && <Modules post={post} />}
              </Wrapper>
            </motion.div>
          )}
        </AnimatePresence>
      </PageContent>
    </>
  )
}

export default Page

const Wrapper = styled.div`
  min-height: calc(100vh - ${props => props.theme.navHeight}px);
  background-color: #fff;
`

const PageContent = styled.div`
  position: relative;
`

const transition = { duration: 1, delay: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }
