import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Spinner from 'ui/components/generic/Spinner'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

class Loading extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    )
  }
}

export default Loading
