import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink
} from '@apollo/client'
import { onError } from '@apollo/link-error'

import possibleTypes from 'possibleTypes.json'

export default (ssr = false, fetch) => {
  // Setup cache
  const cache = new InMemoryCache({
    possibleTypes
  })

  const publicUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST_NAME}/graphql`
  // Setup remote link

  const httpLink = new HttpLink({
    uri: ssr ? 'http://proxy/graphql' : publicUrl,
    fetch
  })

  // Setup error link
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )

        // Ignore graphql errors so we can let the ui handle 404s
        response.errors = null

        return null
      })
    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  // Put it all together
  return new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: ssr ? cache : cache.restore(window.__APOLLO_STATE__),
    ssrMode: ssr
  })
}
