import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import useScrollEntry from 'utils/useScrollEntry'

import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import theme from 'ui/styles/theme'
import easing from 'ui/styles/easing'
import { responsiveText } from 'ui/styles/index'

SwiperCore.use([Navigation, Pagination])

const { colors } = theme

export default ({ heading, milestones }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Container fluid>
        <Row>
          <Col>
            <Header>
              <Heading>{heading}</Heading>
              <div>
                <ButtonPrev className="swiper-prev">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="7">
                    <g fill="none" fillRule="evenodd">
                      <path fill="#0032CD" d="M21 3.5L16 7V0z"></path>
                      <path
                        stroke="#0032CD"
                        strokeLinecap="square"
                        d="M15.5 3.5H.5"
                      ></path>
                    </g>
                  </svg>
                </ButtonPrev>
                <Button className="swiper-next">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="7">
                    <g fill="none" fillRule="evenodd">
                      <path fill="#0032CD" d="M21 3.5L16 7V0z"></path>
                      <path
                        stroke="#0032CD"
                        strokeLinecap="square"
                        d="M15.5 3.5H.5"
                      ></path>
                    </g>
                  </svg>
                </Button>
              </div>
            </Header>
          </Col>
        </Row>
        <Row>
          <Col>
            <Swiper
              slidesPerView={1}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next'
              }}
              pagination={{ type: 'progressbar' }}
              spaceBetween={20}
              breakpoints={{
                575: {
                  slidesPerView: 2
                },

                768: {
                  slidesPerView: 3
                },

                1080: {
                  slidesPerView: 4
                }
              }}
            >
              {milestones.map(milestone => (
                <SwiperSlide>
                  <Year>{milestone.year}</Year>
                  <Text dangerouslySetInnerHTML={{ __html: milestone.text }} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export const query = `
  heading
  milestones {
    year
    text
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
  background: ${colors.tones.lightest};
  padding: 40px 0;
  border-radius: 20px 20px 0 0;
  margin-bottom: 35px;

  ${media.sm`
    margin-bottom: 150px;
  `}

  .swiper-wrapper {
    margin-top: 20px;
  }

  .swiper-pagination-progressbar {
    height: 1px;
    background: ${colors.tones.light};

    .swiper-pagination-progressbar-fill {
      background: ${colors.primaryDark};
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const Heading = styled.h2`
  ${responsiveText('xxs')};
  text-transform: uppercase;
  margin: 0;
`

const Year = styled.h3`
  color: ${colors.primaryDark};
  font-size: 30px;
  margin-bottom: 5px;
`

const Text = styled.div`
  color: ${colors.tones.medium};
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 10px;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  svg path {
    transition: fill 0.3s ${easing.easeOutCubic},
      stroke 0.3s ${easing.easeOutCubic};
  }

  &.swiper-button-disabled {
    svg path {
      fill: ${colors.tones.medium};
      stroke: ${colors.tones.medium};
    }
  }
`

const ButtonPrev = styled(Button)`
  svg {
    transform: rotate(180deg);
  }
`
