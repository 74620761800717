import scrollTo from 'animated-scroll-to'
import theme from 'ui/styles/theme'

export default (target, duration = null) => {
  let targetElement = target

  if (typeof target === 'string') {
    targetElement = document.querySelector(target)
  }

  scrollTo(targetElement, {
    verticalOffset: -theme.navHeight,
    speed: 800,
    minDuration: duration || 500,
    maxDuration: duration || 3000
  })
}
