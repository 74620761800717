import { useState, useEffect, useCallback } from 'react'

export default (ref, offset = 0.85, once = true) => {
  const [visible, setVisible] = useState(false)

  const handleScroll = useCallback(() => {
    if (ref) {
      const rect = ref.current.getBoundingClientRect()
      if (rect.top < window.innerHeight * offset) {
        // Remove event listener when
        if (once) {
          window.removeEventListener('scroll', handleScroll)
        }

        if (!visible) {
          setVisible(true)
        }
      } else {
        if (visible) {
          setVisible(false)
        }
      }
    }
  }, [ref, visible, offset, once])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return [visible]
}
