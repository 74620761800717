import React, { useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import { responsiveText } from 'ui/styles'
import getRelativeUrl from 'utils/getRelativeUrl'
import useScrollEntry from 'utils/useScrollEntry'

import theme from 'ui/styles/theme'

const { colors } = theme

export default ({ title, links }) => {
  const ref = useRef(null)
  const visible = useScrollEntry(ref)

  return (
    <Wrapper ref={ref} visible={visible}>
      <Links>
        <Container fluid>
          <LinksHeader>{title}</LinksHeader>
          <Row>
            {links.map((link, index) => (
              <Col key={index} sm={24 / links.length} md={12 / links.length}>
                {link.type === 'none' ? (
                  <div>
                    <LinkHeader>{link.title}</LinkHeader>
                    <LinkText>{link.text}</LinkText>
                  </div>
                ) : link.type === 'internal' ? (
                  <Link to={getRelativeUrl(link.link)}>
                    <LinkHeader>{link.title}</LinkHeader>
                    <LinkText>{link.text}</LinkText>
                  </Link>
                ) : (
                  <a href={link.link} target="_blank" rel="noreferrer noopener">
                    <LinkHeader>{link.title}</LinkHeader>
                    <LinkText>{link.text}</LinkText>
                  </a>
                )}
              </Col>
            ))}
          </Row>
        </Container>
      </Links>
    </Wrapper>
  )
}

export const query = `
  title
  links {
    title
    text
    link
    type
  }
`

// Styles
const Wrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translateY(${props => (props.visible ? 0 : 50)}px);
  transition: 500ms;
`

const Links = styled.section`
  ${responsiveText('xs')}
  padding: 20px 0;
  background-color: ${colors.tones.lightest};
  color: ${colors.tones.darkest};
`

const LinksHeader = styled.h2`
  ${responsiveText('xxs')}
  border-bottom: 1px solid ${colors.tones.light};
  padding-bottom: 20px;
  text-transform: uppercase;
`

const LinkHeader = styled.h2`
  ${responsiveText('xxs')}
  text-transform: uppercase;
  padding-left: 20px;
  position: relative;
  margin: 0 0 5px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 0px;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: ${colors.primaryDark};
  }
`

const LinkText = styled.p`
  padding-left: 20px;
  color: ${colors.textLight};
`
