import styled, { css } from 'styled-components'
import { media } from 'styled-bootstrap-grid'

import theme from './theme'
import responsiveText from './responsiveText'
import responsiveSpacing from './responsiveSpacing'

export const Relative = styled.div`
  position: relative;
`

export const Margin = styled.div``

export const Text = styled.div`
  /**
  * Font size
  **/
  ${props => responsiveText(props.size)}

  /**
  * Font-weight
  **/
  ${props =>
    typeof props.weight !== 'undefined' &&
    css`
      font-weight: ${props =>
        props.theme.fontWeights[props.weight] || 'normal'};
    `}

  /**
  * Color
  **/
  ${props =>
    typeof props.color !== 'undefined' &&
    css`
      color: ${props.color};
    `}

  /**
  * Margin
  **/
  ${props =>
    typeof props.mt !== 'undefined' &&
    css`
      margin-top: ${props.mt}px;
    `}

  ${props =>
    typeof props.mb !== 'undefined' &&
    css`
      margin-bottom: ${props.mb}px;
    `}

  ${props =>
    typeof props.ml !== 'undefined' &&
    css`
      margin-left: ${props.ml}px;
    `}

  ${props =>
    typeof props.mr !== 'undefined' &&
    css`
      margin-right: ${props.mr}px;
    `}

  /**
  * Align
  **/
  ${props =>
    typeof props.align !== 'undefined' &&
    css`
      text-align: ${props.align};
    `}
`

export const BodyText = styled.div`
  p {
    font-weight: 200;
    ${responsiveText('md')}
    color: ${props => props.theme.colors.tones.dark};
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: ${responsiveSpacing(3, 'margin-top')};
    font-weight: 600;

    &:first-child {
      margin-top: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;

    &.alignleft {
      float: left;
      margin-right: 20px;
    }

    &.alignright {
      float: right;
      margin-left: 20px;
    }

    &.aligncenter {
      margin: auto;
    }

    ${media.xs`
      &.alignleft, &.alignright, &.aligncenter {
        width: 100%;
        float: none;
        margin: 20px 0;
      }
    `}
  }
`

export const getThemeColor = (color, customColor, t = theme) => {
  if (color === 'custom') return customColor
  return t.colors[color] || t.colors.primaryDark
}
