import React, { useRef, useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link as LinkComponent } from 'react-router-dom'
import { Container, media } from 'styled-bootstrap-grid'

import { getRelativeUrl } from 'utils'
import {
  motion,
  useViewportScroll,
  useTransform,
  useSpring
} from 'framer-motion'

import useStrings from 'containers/useStrings'
import { theme, responsiveText } from 'ui/styles'

const elWidth = 320
const gutterWidth = 20

export default ({ header, label1, label2, links }) => {
  const wrapper = useRef(null)
  const inner = useRef(null)

  const { t } = useStrings()

  const [dimensions, setDimensions] = useState({
    offset: 0,
    height: 0,
    innerHeight: 0
  })
  const [position, setPosition] = useState(false)

  const { height, offset, innerHeight } = dimensions

  const linksWidth = elWidth * links.length + gutterWidth * (links.length - 1)
  const windowWidth = typeof window !== 'undefined' ? window.innerWidth : 0

  const updateDimensions = useCallback(() => {
    if (wrapper.current) {
      const newOffset =
        window.pageYOffset + wrapper.current.getBoundingClientRect().top + 40 // module is transformed -40 when initialized
      const newHeight = wrapper.current.clientHeight
      const newInnerHeight = inner.current.clientHeight
      if (
        newOffset !== offset ||
        newHeight !== height ||
        newInnerHeight !== innerHeight
      ) {
        setDimensions({
          offset: newOffset,
          height: newHeight,
          innerHeight: newInnerHeight
        })
      }
    }
  }, [wrapper, setDimensions, offset, height, innerHeight])

  const handleScroll = useCallback(() => {
    if (window.pageYOffset < offset - theme.navHeight) {
      if (position !== 'above') {
        setPosition('above')
      }
    } else if (
      window.pageYOffset > offset - theme.navHeight &&
      window.pageYOffset < offset + height - innerHeight - theme.navHeight
    ) {
      if (position !== 'inView') {
        setPosition('inView')
      }
    } else {
      if (position !== 'below') {
        setPosition('below')
      }
    }
  }, [offset, height, position, innerHeight])

  useEffect(() => {
    updateDimensions()
  }, [wrapper, updateDimensions])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', updateDimensions)
    }
  }, [handleScroll, updateDimensions])

  // Get the Y-position
  const { scrollY } = useViewportScroll()

  // Define progress based on scroll position and dimensions
  const progress = useSpring(
    useTransform(
      scrollY,
      [offset, offset + height - innerHeight - theme.navHeight],
      [0, 1],
      { clamp: false }
    ),
    { stiffness: 400, damping: 90 }
  )

  // Transform x position from progress. The progress values can be tweaked to define
  // when the scrolling starts and ends
  const x = useTransform(
    progress,
    [-1.5, 1],
    [windowWidth, windowWidth - linksWidth - 30],
    { clamp: true }
  )

  // Transform progress bar to match above settings
  const progressBar = useTransform(progress, [-0.5, 1], [0, 1])

  return (
    <Wrapper ref={wrapper}>
      <Inner position={position} ref={inner}>
        <Content>
          <Container fluid>
            <Header>
              {header}
              <Progress scaleX={progressBar} originX={0} />
            </Header>
            <Labels>
              <Label>{label1}</Label>
              <Label>{label2}</Label>
            </Labels>
          </Container>
          <Links width={linksWidth} x={x}>
            {links.map(({ line1, line2, image, link }, index) => (
              <Link
                key={index}
                gutterWidth={gutterWidth}
                width={elWidth}
                whileHover={{ width: `${elWidth * 1.3}px` }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 200
                }}
              >
                <LinkComponent to={getRelativeUrl(link)}>
                  <LinkHeader>
                    <Line1>{line1}</Line1>
                    <Line2>{line2}</Line2>
                  </LinkHeader>
                  <Image src={image.sourceUrl} />
                  <ReadMore>
                    {t('readMore')}
                    <span>⟶</span>
                  </ReadMore>
                </LinkComponent>
              </Link>
            ))}
          </Links>
        </Content>
      </Inner>
    </Wrapper>
  )
}

export const query = `
  header
  label1
  label2
  links {
    line1
    line2
    image {
      altText
      sourceUrl
    }
    link
  }
`

const Wrapper = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  height: 300vh;
  position: relative;

  ${media.xs`
    height: auto;
  `}
`

const Inner = styled(motion.div)`
  position: ${props => (props.position === 'inView' ? 'fixed' : 'absolute')};
  overflow: hidden;
  top: ${props =>
    props.position === 'inView'
      ? `${props.theme.navHeight}px`
      : props.position === 'above'
      ? '0px'
      : 'auto'};
  bottom: ${props => (props.position === 'below' ? '0px' : 'auto')};
  left: 0;
  width: 100%;
  height: calc(100vh - ${props => props.theme.navHeight}px);
  display: flex;
  align-items: center;

  ${media.xs`
    position: relative;
    height: auto;
  `}
`

const Content = styled.div`
  width: 100%;
`

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  ${media.xs`
    display: none;
  `}
`

const Label = styled.div`
  ${responsiveText('xxs')}
  text-transform: uppercase;
  font-weight: normal;
`

const Header = styled.h2`
  position: relative;
  padding-bottom: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.tones.lightest};
  ${responsiveText('md')}
  margin-bottom: 5px;

  ${media.xs`
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`

const Progress = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  height: 1px;
  left: 0px;
  width: 100%;
  background-color: ${props => props.theme.colors.secondary};

  ${media.xs`
    display: none;
  `}
`

const Links = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
  width: ${props => props.width}px;

  ${media.xs`
    width: 100%;
    transform: none !important;
    display: block;
    height: auto !important;
  `}
`

const LinkHeader = styled.h2`
  ${responsiveText('md')}
  color: #fff;
  z-index: 10;
  line-height: 0.9;

  ${media.xs`
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin-bottom: 0;
  `}
`

const Line = styled.span`
  display: block;
  line-height: 25px;

  ${media.xs`
    display: inline-block;
    color: #fff;
    font-size: 25px;
    line-height: 22px;
  `}
`

const Line1 = styled(Line)`
  color: ${props => props.theme.colors.primaryDark};

  ${media.xs`
    margin-right: 10px;
  `}
`

const Line2 = styled(Line)`
  color: ${props => props.theme.colors.tones.lighter};
`

const Image = styled(motion.div)`
  position: relative;
  width: 100%;
  height: ${elWidth * 1.3}px;
  overflow: hidden;

  ${media.xs`
    height: ${elWidth * 0.8}px;
  `}

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    width: ${elWidth * 1.3}px;
    margin-left: -${(elWidth * 1.3) / 2}px;
    height: 100%;
    top: 0px;

    background-image: url('${props => props.src}');
    background-size: cover;
    background-position: center;
  }
`

const ReadMore = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
  left: ${gutterWidth / 2 + 30}px;
  right: ${gutterWidth / 2 + 30}px;
  transform: translateX(-100%);
  opacity: 0;
  transition: 500ms;
  color: #fff;
  text-transform: uppercase;
  ${responsiveText('xxs')}
  z-index: 10;

  span {
    transform: translateX(-20px);
    transition: 700ms 250ms;
    opacity: 0;
  }

  ${media.xs`
    display: none;
  `}
`

const Link = styled(motion.div)`
  position: relative;
  width: ${props => props.width + props.gutterWidth}px;
  padding-left: ${props => props.gutterWidth / 2}px;
  padding-right: ${props => props.gutterWidth / 2}px;
  overflow: hidden;

  a {
    display: block;
    width: 100%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: ${gutterWidth / 2}px;
    right: ${gutterWidth / 2}px;
    height: 100%;

    background: rgb(0, 50, 205);
    background: linear-gradient(rgba(0, 50, 205, 0) 50%, rgb(0, 50, 205) 100%);
    pointer-events: none;
    transform: translateY(50%);
    transition: 500ms;

    ${media.xs`
      transform: none !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    `}
  }

  &:hover {
    &:after {
      transform: translateY(0%);
    }

    ${ReadMore} {
      transform: translateX(0);
      opacity: 1;

      span {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  ${media.xs`
    width: 100%;
    margin-bottom: 20px;

    a {
      display: block;
      position: relative;
      overflow: hidden;
    }
  `}
`
